body {
  //Sync
  --For-development: rgba(255, 138, 0, 1);
  --LTA-Colors--Neutral--N0: rgba(255, 255, 255, 1); //#FFFFFFFF
  --LTA-Colors--Neutral--N100: rgba(242, 245, 250, 1); //#F2F5FAFF
  --LTA-Colors--Neutral--N200: rgba(230, 237, 250, 1); //#E6EDFAFF
  --LTA-Colors--Neutral--N300: rgba(215, 227, 250, 1); //#D7E3FAFF
  --LTA-Colors--Neutral--N400: rgba(124, 142, 178, 1); //#7C8EB2FF
  --LTA-Colors--Neutral--N500: rgba(43, 65, 110, 1); //#2B416EFF
  --LTA-Colors--Neutral--N600: rgba(33, 50, 84, 1); //#213254FF
  --LTA-Colors--Neutral--N700: rgba(23, 35, 59, 1); //#17233BFF
  --LTA-Colors--Neutral--N800: rgba(0, 0, 0, 1); //#000000FF
  --LTA-Colors--Neutral--Runtime--N700: rgba(17, 26, 43, 1); //#111A2BFF
  --LTA-Colors--Primary--PB500: rgba(59, 124, 255, 1); //#3B7CFFFF
  --LTA-Colors--Primary--PG400: rgba(0, 217, 61, 1); //#00D93DFF
  --LTA-Colors--Primary--PG500: rgba(47, 204, 91, 1); //#2FCC5BFF
  --LTA-Colors--Primary--PG600: rgba(0, 184, 88, 1); // #00B858FF
  --LTA-Colors--Primary--PR400: rgba(250, 227, 231, 1); //#FAE3E7FF
  --LTA-Colors--Primary--PR500: rgba(255, 59, 94, 1); //#FF3B5EFF
  --Neutral-Run-time-N400: #355087;
  --Neutral-Run-time-N500: #213254;
  --Neutral-Run-time-N600: #17233B;

  //Not sync
  --LTA-warning: #FFBC3B;
  --LTA-switch-off: #D5E0F5;
  --LTA-formula-modal-text: #8FA0BF;
  --LTA-tree-descr-color: #8FA0BF;
  --LTA-contex-menu-box-shadow: rgba(59, 124, 255, 0.1);
  --LTA-model-component-widget-box-shadow: rgba(59, 124, 255, 0.03);
  --LTA-hmi-editor-background: #304F8C;
  --LTA-modal-background: rgba(22, 35, 61, 0.2);
  --LTA-dark-tree-toggler: #6397FF;
  --LTA-dark-input-border: #355087;
  --LTA-light-input-border: #D7E3FA;
  --LTA-dark-alert-select-background: #30497A;
  --LTA-not-found-title-color: #5378C2;
  --LTA-bind-panel-binded: #00CB00;
  --LTA-bind-panel-unbinded: #FFA800;
  --LTA-arrow-color: #FFD453;
  --LTA-input-hover-color: #E9EDF5;
  --LTA-dark-icon: #496EBA;
  --LTA-placeholder-color: #8FA0BF;
  --LTA-calendar-renge-color: #2B5BBA;
  --LTA-zoom-slider-bar: #374F80;
  --LTA-zoom-slider-pointer: #6D9EFF;
  --LTA-settings-general: #666666;
  --LTA-settings-figma: #7720F9;
  --LTA-settings-language: #20A2F9;
  --LTA-settings-periods: #20A2F9;
  --LTA-settings-intervals: var(--LTA-Colors--Primary--PG400);
  --LTA-alerts-table-btn: #2C5199;
  --LTA-btn-hover: #2B6DF0;
  --LTA-btn-danger-hover: #FAD4DB;
  --LTA-btn-success-hover: #22BD4D;
  --LTA-btn-dashed-border: #D5E0F5;
  --LTA-btn-focus: #E3EBFA;
  --LTA-btn-focus-border: #3B9DFF;
  --LTA-btn-disabled: #DFE4ED;
  --LTA-cluster-dot-gray: #B3B3B3;
  --LTA-driver-dot-pink: #FF33FF;
  --LTA-driver-dot-error: #E4C000;
  --LTA-signal-selector-background: #121B2E;
  --LTA-signal-selector-empty: #17233B;
  --LTA-pagination-dark-selected: #3F5FA1;


  // IMAGES
  --LTA-avatar-placeholder: url('~assets/images/no-avatar.png');
  --LTA-avatar-default: url('~assets/images/default-avatar.png');
}
