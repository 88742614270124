.ngx-contextmenu {
  --ngx-contextmenu-font-family: 'Inter';
  --ngx-contextmenu-min-width: 190px;
  --ngx-contextmenu-margin: 0;
  --ngx-contextmenu-padding: 10px 0;
  --ngx-contextmenu-border-radius: 8px;
  --ngx-contextmenu-background-color: var(--LTA-Colors--Neutral--N600);
  --ngx-contextmenu-box-shadow: 0 10px 10px var(--LTA-contex-menu-box-shadow);
  --ngx-contextmenu-outline: none;
  font-weight: 500;

  user-select: none;

  .ngx-context-menu-item {
    --ngx-contextmenu-font-size: 14px;
    --ngx-contextmenu-text-color: var(--LTA-Colors--Neutral--N0);
    --ngx-contextmenu-item-background-hover-color: var(--LTA-dark-alert-select-background);
    --ngx-contextmenu-item-text-hover-color: var(--LTA-Colors--Neutral--N0);
    cursor: pointer;

    &.ngx-context-menu-item[role=separator] {
      --ngx-contextmenu-item-separator-color: transparent;
      --ngx-contextmenu-item-separator-width: 100%;
      --ngx-contextmenu-item-background-hover-color: var(--LTA-Colors--Neutral--N500);
      margin: 10px 0;
      height: 2px;
      background: var(--LTA-Colors--Neutral--N500);
      cursor: default;
    }
  }

  .danger {
    color: var(--LTA-Colors--Primary--PR500);
  }

  .disabled {
    color: var(--LTA-Colors--Neutral--N400);
  }
}


.driverErrorContextmenu {
  --ngx-contextmenu-min-width: 190px;
  --ngx-contextmenu-margin: 0;
  --ngx-contextmenu-padding: 10px 0;
  --ngx-contextmenu-border: 2px solid var(--LTA-Colors--Neutral--N200);
  --ngx-contextmenu-border-radius: 10px;
  --ngx-contextmenu-background-color: var(--LTA-Colors--Neutral--N0);
  --ngx-contextmenu-box-shadow: 0 10px 10px var(--LTA-Colors--Neutral--N300);
  --ngx-contextmenu-outline: none;

  .ngx-context-menu-item {
    cursor: default;
    --ngx-contextmenu-text-color: var(--LTA-Colors--Neutral--N700);
    --ngx-contextmenu-item-background-hover-color: transparent;
    --ngx-contextmenu-item-text-hover-color: var(--LTA-Colors--Neutral--N700);
  }
}

.context-menu-mnemo-style {
  --ngx-contextmenu-border: 2px solid #355087;
}

