@import '~normalize.css/normalize.css';
@import 'fonts.css';
@import "/src/app/@atl/styles/styles";
@import '~@ali-hm/angular-tree-component/css/angular-tree-component.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import '/node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import '/node_modules/air-datepicker/air-datepicker.css';

body {
  overflow: hidden
}

.bullet-tooltip {
  position: fixed;
  max-width: 250px;
  padding: 6px;
  border-radius: 4px;
  border: 2px solid #fff;
  opacity: 0;
  pointer-events: none;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;

    position: absolute;
    border-bottom: solid 10px transparent;
    border-top: solid 10px #fff;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 50%);
    pointer-events: none;
  }

  &::after {
    border-top: solid 10px currentColor;
    bottom: -7px;
  }

  p {
    font-size: 12px;
    margin: 6px 0;
    color: var(--LTA-Colors--Neutral--N700);

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.series-tooltip {
  position: fixed;
  max-width: 250px;
  padding: 6px;
  border-radius: 4px;
  //border: 1px solid #fff;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
  }

  &.adjusted {
    &::before,
    &::after {
      visibility: hidden;
    }
  }

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;

    position: absolute;
    border-bottom: solid 6px transparent;
    border-top: solid 6px transparent;
    //border-right: solid 6px #fff;
    border-left: solid 6px transparent;
    top: 50%;
    left: -6px;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  &::after {
    border-right: solid 6px currentColor;
    left: -4px;
  }

  p {
    font-size: 12px;
    margin: 6px 0;
    color: var(--LTA-Colors--Neutral--N700);

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}
