@use 'mixins';
@import "variebles";
@import "context-menu";


* {
  box-sizing: inherit;
}

html, body {
  font-family: 'Inter', sans-serif;
  line-height: 1.3;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: var(--LTA-Colors--Neutral--N700);
  background-color: var(--LTA-Colors--Neutral--N0);
  font-feature-settings: 'ss04' on;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.table-list {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(auto-fit, 38px);
  grid-row-gap: 10px;

  &__row {
    display: contents;
  }

  &__label {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.modal-message {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;

  .current-item {
    color: var(--LTA-Colors--Primary--PB500);
  }
}

.tree-ngx {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.text-ellipsis {
  overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0
}

.allow-select {
  user-select: auto !important;
}

.tooltip-container {
  @include mixins.fontInter(normal, 500, 12px, 100%);
  position: fixed;
  z-index: 10000;
  box-sizing: border-box;
  width: min-content;
  padding: 3px 12px 4px 12px;
  animation: tooltip-slide 0.18s ease-out 0.5s;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  color: var(--LTA-Colors--Neutral--N0);
  border-radius: 4px;
  background: var(--LTA-Colors--Neutral--N700);
  box-shadow: 0px 10px 10px var(--LTA-contex-menu-box-shadow);
  animation-fill-mode: forwards;

  &.light {
    background: var(--LTA-Colors--Neutral--N0);
    border: 2px solid var(--LTA-Colors--Neutral--N200);
    color: var(--LTA-Colors--Neutral--N700);
    padding: 10px 14px;
  }

  &.full-width {
    width: auto;
  }

  &.text-align-left {
    text-align: left;
  }
}

.pointer {
  cursor: pointer;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

lta-tree-settings-item {
  display: flex;
  gap: 5px;
}

.table .selectable {
  user-select: text;
}

.mb-20 {
  margin-bottom: 20px;
}

.white-icon {
  filter: var(--ICON-white);
}

